import React from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  transition: opacity 500ms ease;

  &.fade {
    opacity: 0;
  }
`

const Row = styled.div`
  margin-bottom: -5px;
`

const introKeyframes = (from, to) => keyframes`
  from {
    transform: translateZ(0) ${from};
  }

  to {
    transform: translateZ(0) ${to};
  }
`

const introTop = introKeyframes('translateY(-60vh)', 'translateY(0)')
const introBottom = introKeyframes('translateY(60vh)', 'translateY(0)')
const outroTop = introKeyframes('translateY(0)', 'translateY(-60vh)')
const outroBottom = introKeyframes('translateY(0)', 'translateY(60vh)')
const easing = 'ease'

const Letter = styled.img`
  animation-fill-mode: both !important;
  animation-iteration-count: 1;
  backface-visibility: hidden;
  height: 38px;
  margin-bottom: -1px;
  margin-right: 2px;
  transform: translateZ(0) translateY(110vh) translateX(110vw);
  width: auto;

  &.intro-top {
    animation: ${introTop} 1s ${easing};
  }

  &.intro-bottom {
    animation: ${introBottom} 1s ${easing};
  }

  &.outro-top {
    animation: ${outroTop} 1s ${easing};
  }

  &.outro-bottom {
    animation: ${outroBottom} 1s ${easing};
  }
`

const lettersPixel = [
  {
    char: 'p',
    className: 'intro-top',
  },
  {
    char: 'i',
    className: 'intro-top',
  },
  {
    char: 'x',
    className: 'intro-top',
  },
  {
    char: 'e',
    className: 'intro-top',
  },
  {
    char: 'l',
    className: 'intro-top',
  },
]

const lettersBreaker = [
  {
    char: 'b',
    className: 'intro-bottom',
  },
  {
    char: 'r',
    className: 'intro-bottom',
  },
  {
    char: 'e',
    className: 'intro-bottom',
  },
  {
    char: 'a',
    className: 'intro-bottom',
  },
  {
    char: 'k',
    className: 'intro-bottom',
  },
  {
    char: 'e',
    className: 'intro-bottom',
  },
  {
    char: 'r',
    className: 'intro-bottom',
  },
]

class SplashLogo extends React.Component {
  state = {
    outroState: 0,
  }

  defaultProps = {
    onComplete: () => {},
  }

  componentDidMount() {
    this.outroTimer = setTimeout(this.startOutro, 2500)
  }

  startOutro = () => {
    this.setState({ outroState: 1 })
    this.outroTimer = setTimeout(this.endOutro, 1000)
  }

  endOutro = () => {
    this.setState({ outroState: 2 })
    clearTimeout(this.outroTimer)
    this.props.onComplete()
  }

  render() {
    const { outroState } = this.state
    return (
      <Container>
        <Row>
          {lettersPixel.map(({ char, className, style }, i) => (
            <Letter
              key={i}
              src={`/img/logo-${char}.svg`}
              className={
                outroState > 0 ? className.replace('intro', 'outro') : className
              }
              style={{
                animationDelay: `${Math.floor(Math.random() * 800)}ms`,
                animationDuration: `${200 + Math.floor(Math.random() * 800)}ms`,
              }}
            />
          ))}
        </Row>
        <Row>
          {lettersBreaker.map(({ char, className, style }, i) => (
            <Letter
              key={i}
              src={`/img/logo-${char}.svg`}
              className={
                outroState > 0 ? className.replace('intro', 'outro') : className
              }
              style={{
                animationDelay: `${Math.floor(Math.random() * 800)}ms`,
                animationDuration: `${200 + Math.floor(Math.random() * 800)}ms`,
              }}
            />
          ))}
        </Row>
      </Container>
    )
  }
}

export default SplashLogo
