import React from 'react'
import { Link } from 'gatsby'
import '../sass/site.scss'
import styled from 'styled-components'
import SplashLogo from './SplashLogo'

const ANIM_RESHOW_THRESHOLD = 2 * 60 * 1000 //5 * 60 * 1000

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
`

const Splash = styled.div`
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 300ms ease;
  z-index: 100;

  &.fade {
    opacity: 0;
  }
`

const Footer = styled.footer`
  margin: 4rem 0 0;
`

class Layout extends React.Component {
  constructor() {
    super()
    let showSplash = true
    if (typeof window !== `undefined`) {
      const lastLoad = window.localStorage.getItem('lastLoad')
      showSplash = new Date().valueOf() - lastLoad > ANIM_RESHOW_THRESHOLD

      window.localStorage.setItem('lastLoad', new Date().valueOf())
    }
    this.state = { showSplash, fade: false }
  }

  onSplashComplete = () => {
    this.setState({ fade: true })
    setTimeout(this.hideSplash, 500)
  }

  hideSplash = () => {
    this.setState({ showSplash: false })
  }

  render() {
    const { location, title, children } = this.props
    const { showSplash, fade } = this.state
    const splashClassName = fade ? 'fade' : ''

    const rootPath = `${__PATH_PREFIX__}/`
    const header = (
      <Link className="clean" to={`/`}>
        <img src="/img/logo.svg" alt="Pixelbreaker logo" />
      </Link>
    )

    return (
      <Container>
        {showSplash && (
          <Splash className={splashClassName}>
            <SplashLogo onComplete={this.onSplashComplete} />
          </Splash>
        )}
        <header>{header}</header>
        <main>{children}</main>
        <Footer>© {new Date().getFullYear()}, Pixelbreaker Ltd</Footer>
      </Container>
    )
  }
}

export default Layout
